
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { ICustomer, ICustomerListRequest } from "@/types/customer";
import {
  ICustomerStaff,
  ICustomerStaffCreateRequest,
  ICustomerStaffRequest,
  ICustomerStaffUpdateRequest
} from "@/types/customer_staff";
import { IBranch, IBranchListRequest } from "@/types/branch";
import { CustomerStaffRequest } from "@/models/customer_staff";

@Component({})
export default class extends Vue {
  //customer_staff
  @Action("customer_staff/adminGet")
  public getCustomerStaff!: (customer_staff_id: number) => void;

  @Action("customer_staff/adminCreate")
  public create!: (params: ICustomerStaffCreateRequest) => boolean;

  @Action("customer_staff/adminUpdate")
  public update!: (data: {
    customer_staff_id: number;
    params: ICustomerStaffUpdateRequest;
  }) => boolean;

  @Action("customer_staff/adminDelete")
  public deleteCustomerStaff!: (customer_staff_id: number) => boolean;

  @Getter("customer_staff/single")
  public customer_staff!: ICustomerStaff;

  @Mutation("customer_staff/clear")
  public clear!: () => void;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => boolean;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  // branch
  @Action("branch/adminGetList")
  public getBranches!: (params: IBranchListRequest) => boolean;

  @Getter("branch/selectItem")
  public branchList!: ISelectItem[];

  @Getter("branch/find")
  public findBranch!: (id: number) => IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  public customer_staff_id = 0;
  public customer_id = 0;
  public isCustomerStaff = false;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;

  public rules = {
    name: [(v: string) => !!v || "担当者名は必須です"],
    kana: [
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶﾀｶﾅで入力してください"
    ],
    email: [
      (v: string) =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "正しいメールアドレスを入力してください"
    ],
    tel: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    tel2: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    tel3: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    customer_id: [(v: number) => !!v || "顧客は必須です"]
  };

  public params: ICustomerStaffRequest = new CustomerStaffRequest();

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.customer_staff_id = Number(this.$route.params.customer_staff_id) || 0;
    this.params.customer_id = Number(this.$route.params.customer_id) || 0;

    this.clear();

    // 編集時
    if (this.customer_staff_id) {
      this.isCustomerStaff = true;
      await Promise.all([
        this.getCustomerStaff(this.customer_staff_id),
        this.getCustomers({ per_page: 0 })
      ]);
      await this.getBranches({
        per_page: 0,
        customer_id: this.customer_staff.customer_id
      });
      this.setDefault();
    } else {
      await Promise.all([
        this.getCustomers({ per_page: 0 }),
        this.getBranches({ per_page: 0, customer_id: this.params.customer_id })
      ]);
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromCustomerStaff(this.customer_staff);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.customer_staff_id) {
      result = await this.update({
        customer_staff_id: this.customer_staff_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteCustomerStaff(this.customer_staff_id)) {
      this.$router.go(-1);
    }
  }

  //顧客選択時の挙動
  public async customerSelected() {
    this.params.branch_id = 0;
    await this.getBranches({
      per_page: 0,
      customer_id: this.params.customer_id
    });
  }
}
